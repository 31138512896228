@font-face {
	font-family: 'Outfit';
	font-style: normal;
	font-weight: normal;
	font-display: swap;
	src: local('Outfit'), url('../public/fonts/Outfit-Regular.woff') format('woff');
}

* {
	margin: 0;
	padding: 0;
	list-style-type: none;
	box-sizing: border-box;
	font-family: 'Outfit' !important;
}

a {
	text-decoration: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
	box-shadow: 0 0 0 30px white inset !important;
	background-color: white;
}

input::-webkit-search-cancel-button {
	right: 0;
	width: 20px;
	height: 20px;
	position: relative;
	border-radius: 10px;
	-webkit-appearance: none;
	background: url('/src/icons/clear.svg') no-repeat 50% 50%;
}

#root {
	width: 100vw;
	height: 100vh;
	display: flex;
	overflow: hidden;
	align-items: center;
	justify-content: center;
}

:root {
	--main-color: #7f7eff;
	--light-purple: #b4b5fe;
	--purple10: rgba(127, 126, 255, 0.25);
	--purple20: #c3d8ff;
	--purple30: rgba(70, 96, 135, 0.1);
	--purple40: rgba(127, 126, 255, 0.1);
	--purple50: #f2f2ff;
	--purple60: #f7f7ff;
	--purple70: #efeefd;
	--pruple80: rgba(96, 110, 234, 0.1);
	--black: #191919;
	--black10: #222b2e;
	--black20: #1a202c;
	--black30: rgba(0, 0, 0, 0.25);
	--black40: rgba(0, 39, 90, 0.15);
	--black50: rgba(25, 25, 25, 0.7);
	--black60: rgba(0, 0, 0, 0.1);
	--black70: #2c2c2e;
	--gray10: #b2b2b2;
	--gray20: rgba(0, 0, 0, 0.15);
	--gray30: #e9eff7;
	--gray40: #f6f7f9;
	--gray50: #90a3bf;
	--gray60: #bababa;
	--gray70: #84878a;
	--gray80: #7d8da6;
	--gray90: #d3d3d3;
	--gray100: rgba(255, 255, 255, 0.5);
	--gray110: rgba(178, 178, 178, 0.25);
	--gray120: rgba(178, 178, 178, 0.1);
	--gray130: rgba(140, 140, 140, 0.25);
	--gray140: #e2e2e2;
	--gray150: #f5f9fd;
	--gray160: rgba(224, 224, 224, 0.5);
	--gray170: #fbfbfd;
	--gray180: #4f5e7b;
	--gray190: #7e7e84;
	--gray200: #7d7d7d;
	--gray210: #8c8c8c;
	--pink: #ffc7f5;
	--red: #ff0000;
	--red10: #ffedee;
	--red20: #ff4a4a;
	--green: #14b814;
	--green10: #36c555;
	--green20: #e6f8e6;
	--red10: rgba(255, 0, 0, 0.25);
	--linear-back: linear-gradient(192.59deg, #eff4fb 0%, #f6f8fc 96.33%);
}

.MuiInputBase-root:before,
.MuiInputBase-root:after {
	border: none !important;
}

/* table  */

.rdt_Table {
	background-color: transparent !important;
}

.rdt_TableHead .rdt_TableHeadRow {
	height: 50px;
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 10px;
	border-radius: 10px;
	color: var(--gray80);
	background: var(--gray30);
}

.rdt_TableBody .rdt_TableRow {
	height: 60px;
}

@media (max-width: 1400px) {
	.rdt_TableBody .rdt_TableRow {
		height: 55px;
	}
}

.rdt_TableBody .rdt_TableRow:nth-of-type(1) {
	border-radius: 10px 10px 0 0;
}

.rdt_TableRow > .rdt_TableCell:last-child {
	min-width: 160px !important;
}

.rdt_TableCell {
	white-space: nowrap;
}

/* map  */

.leaflet-control-attribution {
	display: none;
}
.leaflet-control {
	margin: 25px !important;
}
.leaflet-bottom {
	width: 100%;
}

.leaflet-marker-icon {
	border: none !important;
	width: 24px !important;
	height: 24px !important;
	background: transparent !important;
}
